import partner_data1 from "./dataJaon/partner_data1.json"
import partner_data2 from "./dataJaon/partner_data2.json"
import hallows_data from "./dataJaon/hallows_data.json"
import skill_data from "./dataJaon/skill_data.json"
export default class NewClass {
    // partner_data1 = null;
    // partner_data2 = null;
    // hallows_data = null;
    // skill_data = null;   
    tips = [0, 0];

    async loadJson() {
        let startime = new Date().getTime();
        // return new Promise<void>(resolve => {
        //     // cc.resources.loadDir('json', cc.JsonAsset, (err, assets: cc.JsonAsset[]) => {
        //     //     if (err) {
        //     //         cc.error("json文件有问题：", err)
        //     //         return;
        //     //     }
        //     //     for (let index = 0, l = assets.length; index < l; index++) {
        //     //         let element = assets[index];
        //     //         switch (element.name) {
        //     //             case "partner_data1":
        //     //                   partner_data1 = element.json;
        //     //                 break;
        //     //             case "partner_data2":
        //     //                 partner_data2 = element.json;
        //     //                 break;
        //     //             case "hallows_data":
        //     //                 hallows_data = element.json;
        //     //                 break;
        //     //             case "skill_data":
        //     //                 skill_data = element.json;
        //     //                 break;
        //     //             default:
        //     //                 cc.error("此jsoN沒有配置", element.name)
        //     //                 break;
        //     //         }
        //     //     }
        //     //     resolve();
        //     // })
        //     let endtime1 = new Date().getTime();
        //     console.log('加载json结束用时', endtime1 - startime);
        // })
    }
    // onLoad() {
    //     this.loadJson().then(() => {
    //         this.updateHallowsBaseInfo();
    //         let AHERO = null;
    //         AHERO = partner_data1.data_partner_base[40501];        //卡牌编号
    //         AHERO.lv = 1;                                               //卡牌等级
    //         AHERO.break_lv = 0;                                         //升阶等级
    //         AHERO.star = AHERO.init_star;                               //卡牌星级 AHERO.star当前星级 AHERO.init_star卡牌初始星级
    //         let arrtibute = this.getAttribute2(AHERO);
    //         console.log("英雄面板属性", arrtibute);
    //     })
    // }
    getData(parmas) {
        // this.updateHallowsBaseInfo();
        let AHERO = null;
        AHERO = partner_data1.data_partner_base[parmas.heroid];        //卡牌编号
        AHERO.lv = parmas.herolevel;                                               //卡牌等级
        AHERO.break_lv = parmas.herobreaklevel;                                         //升阶等级
        AHERO.star = parmas.herostar;                               //卡牌星级 AHERO.star当前星级 AHERO.init_star卡牌初始星级
        let arrtibute = this.getAttribute2(AHERO);
        // console.log("英雄面板属性", arrtibute);
        return arrtibute
    }

    /** 获取英雄属性 */
    getAttribute(AHERO) {
        let arrtibute = this.getHeroAttr(AHERO.bid);
        arrtibute = this.makeBranch(AHERO, arrtibute);
        arrtibute = this.makeStar(AHERO, arrtibute);
        arrtibute = this.UpLv(AHERO, arrtibute);
        arrtibute.combat = this.makeCombat(arrtibute);
        return arrtibute;
    }
    /**基础 */
    getHeroAttr(id) {
        let arrtibute = partner_data1.data_partner_attr[id];
        let dst = new HeroAttr();
        for (const key in arrtibute) {
            if (Object.prototype.hasOwnProperty.call(arrtibute, key)) {
                let element = arrtibute[key];
                dst[key] += element;
            }
        }
        return dst;
    }
    /**计算升阶 */
    makeBranch(hero, arrtibute) {
        let data_partner_brach = partner_data2.data_partner_brach;
        let breakkey = hero.type + '_' + hero.break_id + '_' + hero.break_lv;
        let branchjson = data_partner_brach[breakkey];
        // 升阶成长
        arrtibute.add_hp_per += (branchjson.add_hp / 1000 - 1);
        arrtibute.add_atk_per += (branchjson.add_atk / 1000 - 1);
        arrtibute.add_def_per += (branchjson.add_def / 1000 - 1);
        arrtibute.add_speed_per += (branchjson.add_speed / 1000 - 1);
        // 增加属性
        for (let index = 0, l = branchjson.all_attr.length; index < l; index++) {
            let element = branchjson.all_attr[index];
            let key = element[0];
            let value = element[1];
            arrtibute[key] += value;
        }
        return arrtibute;
    }
    /**升星 */
    makeStar(hero, arrtibute) {
        let curkey = hero.bid + '_' + hero.star;
        let starjson = partner_data2.data_partner_star[curkey];
        if (!starjson) {
            console.error("没找到升星数据 makeStar:", curkey);
            return arrtibute;
        }

        // 升星成长
        arrtibute.add_hp_per += (starjson.add_hp / 1000 - 1);
        arrtibute.add_atk_per += (starjson.add_atk / 1000 - 1);
        arrtibute.add_def_per += (starjson.add_def / 1000 - 1);
        arrtibute.add_speed_per += (starjson.add_speed / 1000 - 1);
        // 增加属性
        for (let index = 0, l = starjson.attr.length; index < l; index++) {
            let element = starjson.attr[index];
            let key = element[0];
            let value = element[1];
            arrtibute[key] += value;
        }
        return arrtibute;
    }
    /**升级 */
    UpLv(hero, arrtibute) {
        arrtibute.atk = arrtibute.atk + (hero.lv - 1) * arrtibute.add_atk * (1 + arrtibute.add_atk_per);
        arrtibute.hp_max = arrtibute.hp_max + (hero.lv - 1) * arrtibute.add_hp * (1 + arrtibute.add_hp_per);
        arrtibute.def = arrtibute.def + (hero.lv - 1) * arrtibute.add_def * (1 + arrtibute.add_def_per);
        arrtibute.speed = arrtibute.speed + (hero.lv - 1) * arrtibute.add_speed * (1 + arrtibute.add_speed_per);
        return arrtibute;
    }
    /**计算战力 */
    makeCombat(source) {
        source.hp_max
        let combat = Math.ceil(1 * 0.4) + Math.ceil(source.atk * 2)
            + Math.ceil(source.def * 2.66) + Math.ceil(source.speed * 6.4)
            + Math.ceil(source.crit * 14)//暴击率
            + Math.ceil((source.crit_ratio - 1500) * 20)//爆伤
            + Math.ceil(source.control * 8)//控制
            + Math.ceil(source.control_def * 8)//抗控
            + Math.ceil(source.crit_def * 14)//抗爆
            + Math.ceil((source.hit - 1000) * 10)//命中
            + Math.ceil(source.hurt_free * 18)//免伤
            + Math.ceil(source.dodge * 10)//闪避
            + Math.ceil(source.cure * 2)//治疗
            + Math.ceil(source.be_cure * 2)//受疗
            + Math.ceil(source.hurt_deep * 18)//伤害加成
            + Math.ceil(source.wuli_deep * 18)//物伤
            + Math.ceil(source.mofa_deep * 18)//法伤
            + Math.ceil(source.wuli_free * 18)//物免
            + Math.ceil(source.mofa_free * 18);//法免
        return combat;
    }

    /**属性获取 特殊属性千分制转百分之 */
    getAttribute2(hero) {
        let attribute = this.getAttribute(hero);
        attribute.crit = (attribute.crit / 10);
        attribute.crit_ratio = (attribute.crit_ratio / 10);
        attribute.crit_def = (attribute.crit_def / 10);
        attribute.hit = (attribute.hit / 10);
        attribute.dodge = (attribute.dodge / 10);
        attribute.hurt_free = (attribute.hurt_free / 10);
        attribute.hurt_deep = (attribute.hurt_deep / 10);
        attribute.cure = (attribute.cure / 10);
        attribute.be_cure = (attribute.be_cure / 10);
        attribute.control = (attribute.control / 10);
        attribute.control_def = (attribute.control_def / 10);
        //（物理，魔法）
        attribute.wuli_deep = (attribute.wuli_deep / 10);
        attribute.mofa_deep = (attribute.mofa_deep / 10);
        attribute.wuli_free = (attribute.wuli_free / 10);
        attribute.mofa_free = (attribute.mofa_free / 10);

        //加成项 %
        attribute.hp_max_per = (attribute.hp_max_per / 10);
        attribute.def_per = (attribute.def_per / 10);
        attribute.atk_per = (attribute.atk_per / 10);
        attribute.speed_per = (attribute.speed_per / 10);
        //（物理，魔法）
        attribute.wuli_atk_per = (attribute.wuli_atk_per / 10);
        attribute.mofa_atk_per = (attribute.mofa_atk_per / 10);
        attribute.wuli_def_per = (attribute.wuli_def_per / 10);
        attribute.mofa_def_per = (attribute.mofa_def_per / 10);
        return attribute;
    }
    // update (dt) {}
    updateHallowsBaseInfo(parmas, jihuo) {
        //------------------测试数据,需从服务器获取------------------------------
        let Index = parmas.artifactid;                                              //龙的序号
        let iteminfo = new artfactState(Index);
        iteminfo.finsh = 1;
        iteminfo.jihuo = jihuo;
        iteminfo.jindu = 0;
        iteminfo.level = parmas.level;
        iteminfo.levelrate = parmas.levelrate;
        iteminfo.refineallatk = parmas.refineallatk;
        iteminfo.refinelv = parmas.refinelv;
        iteminfo.show = true;
        iteminfo.skillbid = parmas.skillbid;
        iteminfo.skilllv = parmas.skilllv;
        iteminfo.stonenum = parmas.stonenum;
        //--------------------------------------------------------
        let info1 = this.initInfo(iteminfo);
        let info2 = this.refesInfo(iteminfo);
        // let curper = iteminfo.levelrate;
        let key = Index + '_' + iteminfo.level;
        let nextkey = Index + '_' + (iteminfo.level + 1);
        //当前等级对应的json
        let lvinfo = hallows_data.data_info[key];
        let nextlvinfo = hallows_data.data_info[nextkey];

        let tipscha = [0, 0];
        let tipsname = ['', ''];
        let life = []
        for (let index = 0, len = lvinfo.attr.length; index < len; index++) {
            let element = lvinfo.attr[index];
            if (index > 2) return;

            let attrname = element[0]; //名字
            let attrvalue = element[1]; //值
            let nextvalue = 0;
            if (nextlvinfo) {
                //找到下一个等级同名的值
                for (let index1 = 0, len1 = nextlvinfo.attr.length; index1 < len1; index1++) {
                    let element1 = nextlvinfo.attr[index1];
                    if (element1[0] == attrname) {
                        nextvalue = element1[1] || 0;
                        break;
                    }
                }
            }
            let strname = Key_To_Name_Attrs[attrname];
            if (strname) {
                //计算增加值
                let add = 0;
                if (nextvalue > 0) {
                    let ratio = hallows_data.data_const.temporary_ratio.val || 800;
                    add = Math.round(iteminfo.levelrate / lvinfo.max_lucky * (nextvalue - attrvalue) * (Number(ratio) / 1000));
                }
                //刻印石 加基础属性
                let stone = hallows_data.data_const.stone_attribute;
                if (stone && iteminfo.stonenum > 0) {
                    let stonevalue = 0;
                    for (let index = 0, len2 = (stone.val).length; index < len2; index++) {
                        let element = stone.val[index];
                        if (element && element[0] == attrname) {
                            stonevalue = element[1] || 0;
                            break;
                        }

                    }
                    attrvalue += iteminfo.stonenum * stonevalue;
                }
                tipscha[index] = add - this.tips[index];
                this.tips[index] = add;
                tipsname[index] = strname;
                // this.richList[index].string = strname + ':' + (attrvalue + add);//(iteminfo.lucky > 0 ? `<color=#00ff00>+${add}</c>` : '');
                // console.log(strname + ':' + (attrvalue + add));//元龍生命和攻击
                life.push(attrvalue + add)
                // console.log(life);
            }
        }
        // console.log(info1, info2, life);
        return { info1, info2, life }

    }
    initInfo(iteminfo) {
        //当前神器的等级
        let key = iteminfo.artifactid + '_' + iteminfo.skilllv;
        let skiiinfo = hallows_data.data_skill_up[key];
        let skillid = skiiinfo.skill_bid;
        let refinejson = hallows_data.data_artifact_refine;
        let rekey = iteminfo.artifactid + '_' + iteminfo.refinelv;
        let curinfo = refinejson[rekey];
        let skillinfo = skill_data.skill_data[skillid]
        let str = '';
        let str2 = skillinfo.name + '+' + iteminfo.skilllv + '【精炼+' + iteminfo.refinelv + '级】';
        // let str1 = (skillinfo.hurt_ratio + curinfo.addatk) + '(' + skillinfo.hurt_ratio + '+' + curinfo.addatk + ')'
        let str1 = skillinfo.hurt_ratio 
        // str = skillinfo.desc1 + '+' + str1;
        str = str1
        let skillinfoid = skillinfo.desc1
        let nameid = skillinfo.name
        // console.log("技能等级", str2);
        // console.log("技能介绍", str);
        return { skillinfoid, nameid, str, str2 }
    }
    refesInfo(iteminfo) {
        let info2;
        let key = iteminfo.artifactid + "_" + iteminfo.refinelv;
        let nextkey = iteminfo.artifactid + "_" + (iteminfo.refinelv + 1);
        let skillkey = iteminfo.artifactid + "_" + iteminfo.skilllv;
        let skiiinfo = hallows_data.data_skill_up[skillkey]
        let skillid = skiiinfo.skill_bid;
        let skillinfo = skill_data.skill_data[skillid]
        let refinejson = hallows_data.data_artifact_refine;
        let curinfo = refinejson[key];
        let nextinfo = refinejson[nextkey];
        // console.log(`精炼等级${iteminfo.refinelv}  技能伤害增加${curinfo.addatk}   总伤害${skillinfo.hurt_ratio + curinfo.addatk}`);
        // console.log(`精炼等级${iteminfo.refinelv + 1}  技能伤害增加${nextinfo.addatk}   总伤害${skillinfo.hurt_ratio + nextinfo.addatk}`);
        if (iteminfo.refinelv >= 72) {
            info2 = {
                refinelv: "已滿級",
                addatk: 0,
                totalSkill: skillinfo.hurt_ratio + curinfo.addatk,
            }
        } else {
            info2 = {
                refinelv: iteminfo.refinelv + 1,
                addatk: nextinfo.addatk,
                totalSkill: skillinfo.hurt_ratio + nextinfo.addatk,
            }
        }
        let info = {
            refinelv: iteminfo.refinelv,
            addatk: curinfo.addatk,
            totalSkill: skillinfo.hurt_ratio + curinfo.addatk,
        }

        return { info, info2 }
    }

}
class artfactState {
    artifactid = 0;
    level = 1;
    skilllv = 1;
    refinelv = 0;
    stonenum = 0;//刻印石使用个数
    levelrate = 0; //升级的进度
    finsh = 1;
    jindu = 0; //任务进度
    show = false;
    jihuo = false;

    skillbid = 0;
    refineallatk = 0;
    attrObj = {};
    constructor(id) {
        this.artifactid = +id;
    }
}
let Key_To_Name_Attrs = {
    //基础
    "hp_max": "生命",
    "atk": "攻击",
    "def": "防御",
    "speed": "速度",
    //（物理，魔法）
    "wuli_atk": "物理攻击",
    "mofa_atk": "魔法攻击",
    "wuli_def": "物理防御",
    "mofa_def": "魔法防御",

    // 特殊
    "crit": "暴击率",
    "crit_ratio": "暴击伤害",
    "crit_def": "抗暴",
    "hit": "命中",
    "dodge": "闪避",
    "hurt_free": "免伤",
    "hurt_deep": "伤害加成",
    "cure": "治疗",
    "be_cure": "被治疗",
    "control": "控制",
    "control_def": "抗控",
    //（物理，魔法）
    "wuli_deep": "物伤",
    "mofa_deep": "法伤",
    "wuli_free": "物免",
    "mofa_free": "法免",

    // 基础加成
    "hp_max_per": "生命加成",
    "def_per": "防御加成",
    "atk_per": "攻击加成",
    "speed_per": "速度加成",
    //（物理，魔法）
    "wuli_atk_per": "物理攻击加成",
    "mofa_atk_per": "魔法攻击加成",
    "wuli_def_per": "物理防御加成",
    "mofa_def_per": "魔法防御加成",

    //技能
    "skill": "技能",

    // 废弃
    "hit_magic": "控制",
    "crit_rate": "暴击率",
    "dodge_rate": "闪躲",
    "res": "免伤",
    "dam": "伤害加成",
}

// let IDatas = {
//     //基础属性
//     hp_max,//最大血量
//     atk,//攻击
//     def,//防御
//     speed  //速度

// }

// 英雄属性 **必须保证是可序列化类型**
class HeroAttr {

    static _instance;
    static get instance() {
        if (this._instance == null)
            this._instance = new HeroAttr()
        return this._instance;
    }
    data_partner_attr;  //英雄基础属性表
    data_partner_brach; //
    data_partner_star;

    bid;
    combat = 0;

    // 基础
    // base_atk
    // 后加入
    // join_atk

    // 汇总
    hp_max = 0;
    atk = 0;
    def = 0;
    speed = 0;

    //（物理，魔法）
    mofa_atk = 0;
    wuli_atk = 0;
    wuli_def = 0;
    mofa_def = 0;

    //成长属性
    add_hp = 0;
    add_atk = 0;
    add_def = 0;
    add_speed = 0;

    // 成长加成 （非面板属性忽略）
    add_hp_per = 0;
    add_atk_per = 0;
    add_def_per = 0;
    add_speed_per = 0;

    //特殊 % 千分制
    crit = 0;
    crit_ratio = 1500;
    crit_def = 0;
    hit = 1000;
    dodge = 0;
    hurt_free = 0;
    hurt_deep = 0;
    cure = 0;
    be_cure = 0;
    control = 0;
    control_def = 0;
    //（物理，魔法）
    wuli_deep = 0;
    mofa_deep = 0;
    wuli_free = 0;
    mofa_free = 0;

    //加成项 %
    hp_max_per = 0;
    def_per = 0;
    atk_per = 0;
    speed_per = 0;
    //（物理，魔法）
    wuli_atk_per = 0;
    mofa_atk_per = 0;
    wuli_def_per = 0;
    mofa_def_per = 0;
}